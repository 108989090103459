<template>
  <b-modal id="modal-turno2"
    title="Turno 2"
    size='custom-lg-max'
    body-class="px-0"
    hide-footer
    @hide="validarModal"
  >
    <div class="row mx-0">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-2 offset-sm-2 text-center"><b>Horario</b></div>
          <div class="col-sm-2 offset-sm-6 pl-0">
            <label
              class="col-form-label"
            >Descanso/Intermedio:</label>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-1"></div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 text-center px-0"><b>Ingreso</b></div>
          <div class="col-sm-1 text-center px-0"><b>Salida</b></div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1 text-center"><b>Pacientes</b></div>
          <div class="col-sm-1 pl-0 pr-1 text-center"><b>Tiempo</b></div>
          <div class="col-sm-3 text-center"><b>Consultorio</b></div>
          <div class="col-sm-2 pl-0">
            <div class="row">
              <div class="col-sm-6 pl-2 pr-0">
                <input
                  v-model="tiempoDescanso"
                  @change="ejecutarCalculosTiempoAtencion"
                  type="time"
                  class="form-control pl-2 pr-1"
                >
              </div>
              <div class="col-sm-6 pl-2">
                <p class="col-form-label">Minutos</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Lunes -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkLunes"
                @change="resetearLunes"
                class="form-check-input"
                type="checkbox"
                id="checkLunesModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkLunesModal"
            >Lunes:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="lunesIngreso"
              :disabled="!checkLunes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="lunesSalida"
              :disabled="!checkLunes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pt-1">
            <svg
              @click="replicarHorario()"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-lightning-fill text-warning accion-rapida"
              viewBox="0 0 16 16">
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
            </svg>
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="lunesPacientes"
              :disabled="!checkLunes"
              @keydown="validarCaracter"
              @blur="reValidarLunesPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="lunesTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="lunesConsultorioSelected"
              :disabled="!checkLunes"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(lunesConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Martes -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkMartes"
                @change="resetearMartes"
                class="form-check-input"
                type="checkbox"
                id="checkMartesModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkMartesModal"
            >Martes:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="martesIngreso"
              :disabled="!checkMartes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="martesSalida"
              :disabled="!checkMartes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="martesPacientes"
              :disabled="!checkMartes"
              @keydown="validarCaracter"
              @blur="reValidarMartesPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="martesTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="martesConsultorioSelected"
              :disabled="!checkMartes"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(martesConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Miercoles -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkMiercoles"
                @change="resetearMiercoles"
                class="form-check-input"
                type="checkbox"
                id="checkMiercolesModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkMiercolesModal"
            >Miercoles:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="miercolesIngreso"
              :disabled="!checkMiercoles"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="miercolesSalida"
              :disabled="!checkMiercoles"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="miercolesPacientes"
              :disabled="!checkMiercoles"
              @keydown="validarCaracter"
              @blur="reValidarMiercolesPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="miercolesTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="miercolesConsultorioSelected"
              :disabled="!checkMiercoles"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(miercolesConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Jueves -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkJueves"
                @change="resetearJueves"
                class="form-check-input"
                type="checkbox"
                id="checkJuevesModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkJuevesModal"
            >Jueves:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="juevesIngreso"
              :disabled="!checkJueves"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="juevesSalida"
              :disabled="!checkJueves"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="juevesPacientes"
              :disabled="!checkJueves"
              @keydown="validarCaracter"
              @blur="reValidarJuevesPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="juevesTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="juevesConsultorioSelected"
              :disabled="!checkJueves"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(juevesConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Viernes -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkViernes"
                @change="resetearViernes"
                class="form-check-input"
                type="checkbox"
                id="checkViernesModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkViernesModal"
            >Viernes:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="viernesIngreso"
              :disabled="!checkViernes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="viernesSalida"
              :disabled="!checkViernes"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="viernesPacientes"
              :disabled="!checkViernes"
              @keydown="validarCaracter"
              @blur="reValidarViernesPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="viernesTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="viernesConsultorioSelected"
              :disabled="!checkViernes"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(viernesConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Sabado -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkSabado"
                @change="resetearSabado"
                class="form-check-input"
                type="checkbox"
                id="checkSabadoModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkSabadoModal"
            >Sabado:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="sabadoIngreso"
              :disabled="!checkSabado"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="sabadoSalida"
              :disabled="!checkSabado"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="sabadoPacientes"
              :disabled="!checkSabado"
              @keydown="validarCaracter"
              @blur="reValidarSabadoPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="sabadoTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="sabadoConsultorioSelected"
              :disabled="!checkSabado"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(sabadoConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <!-- Domingo -->
        <div class="row mb-1">
          <div class="col-sm-1 text-right">
            <div class="form-check pt-1">
              <input
                v-model="checkDomingo"
                @change="resetearDomingo"
                class="form-check-input"
                type="checkbox"
                id="checkDomingoModal"
              >
            </div>
          </div>
          <div class="col-sm-1 px-0">
            <label
              class="col-form-label"
              for="checkDomingoModal"
            >Domingo:
            </label>
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="domingoIngreso"
              :disabled="!checkDomingo"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1 pl-1 pr-0">
            <input
              v-model="domingoSalida"
              :disabled="!checkDomingo"
              @change="ejecutarCalculosTiempoAtencion"
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="domingoPacientes"
              :disabled="!checkDomingo"
              @keydown="validarCaracter"
              @blur="reValidarDomingoPacientes"
              type="text"
              class="form-control"
            >
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <input
              v-model="domingoTiempoAtencion"
              disabled
              type="time"
              class="form-control pl-2 pr-1"
            >
          </div>
          <div class="col-sm-4 pr-2">
            <multiselect
              v-model="domingoConsultorioSelected"
              :disabled="!checkDomingo"
              :options="listaConsultorio"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(domingoConsultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';
import { util } from '@/plugins/util';

export default {
  name: 'ModalTurno2',
  components: {
    Multiselect,
  },
  data() {
    return {};
  },
  methods: {
    replicarHorario() {
      this.martesIngreso = this.lunesIngreso;
      this.martesSalida = this.lunesSalida;
      this.miercolesIngreso = this.lunesIngreso;
      this.miercolesSalida = this.lunesSalida;
      this.juevesIngreso = this.lunesIngreso;
      this.juevesSalida = this.lunesSalida;
      this.viernesIngreso = this.lunesIngreso;
      this.viernesSalida = this.lunesSalida;
      this.sabadoIngreso = this.lunesIngreso;
      this.sabadoSalida = this.lunesSalida;
      this.domingoIngreso = this.lunesIngreso;
      this.domingoSalida = this.lunesSalida;
      this.checkLunes = true;
      this.checkMartes = true;
      this.checkMiercoles = true;
      this.checkJueves = true;
      this.checkViernes = true;
      this.checkSabado = true;
      this.checkDomingo = true;
      this.ejecutarCalculosTiempoAtencion();
    },
    resetearLunes() {
      if (this.checkLunes === false) {
        this.lunesIngreso = '';
        this.lunesSalida = '';
        this.lunesPacientes = '';
        this.lunesTiempoAtencion = '';
        this.lunesConsultorioSelected = null;
      }
    },
    resetearMartes() {
      if (this.checkMartes === false) {
        this.martesIngreso = '';
        this.martesSalida = '';
        this.martesPacientes = '';
        this.martesTiempoAtencion = '';
        this.martesConsultorioSelected = null;
      }
    },
    resetearMiercoles() {
      if (this.checkMiercoles === false) {
        this.miercolesIngreso = '';
        this.miercolesSalida = '';
        this.miercolesPacientes = '';
        this.miercolesTiempoAtencion = '';
        this.miercolesConsultorioSelected = null;
      }
    },
    resetearJueves() {
      if (this.checkJueves === false) {
        this.juevesIngreso = '';
        this.juevesSalida = '';
        this.juevesPacientes = '';
        this.juevesTiempoAtencion = '';
        this.juevesConsultorioSelected = null;
      }
    },
    resetearViernes() {
      if (this.checkViernes === false) {
        this.viernesIngreso = '';
        this.viernesSalida = '';
        this.viernesPacientes = '';
        this.viernesTiempoAtencion = '';
        this.viernesConsultorioSelected = null;
      }
    },
    resetearSabado() {
      if (this.checkSabado === false) {
        this.sabadoIngreso = '';
        this.sabadoSalida = '';
        this.sabadoPacientes = '';
        this.sabadoTiempoAtencion = '';
        this.sabadoConsultorioSelected = null;
      }
    },
    resetearDomingo() {
      if (this.checkDomingo === false) {
        this.domingoIngreso = '';
        this.domingoSalida = '';
        this.domingoPacientes = '';
        this.domingoTiempoAtencion = '';
        this.domingoConsultorioSelected = null;
      }
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarLunesPacientes() {
      if (this.lunesPacientes === '') {
        this.lunesTiempoAtencion = '';
        return;
      }
      if (parseInt(this.lunesPacientes, 10) === 0) {
        this.lunesPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.lunesPacientes)) {
        this.lunesPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarMartesPacientes() {
      if (this.martesPacientes === '') {
        this.martesTiempoAtencion = '';
        return;
      }
      if (parseInt(this.martesPacientes, 10) === 0) {
        this.martesPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.martesPacientes)) {
        this.martesPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarMiercolesPacientes() {
      if (this.miercolesPacientes === '') {
        this.miercolesTiempoAtencion = '';
        return;
      }
      if (parseInt(this.miercolesPacientes, 10) === 0) {
        this.miercolesPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.miercolesPacientes)) {
        this.miercolesPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarJuevesPacientes() {
      if (this.juevesPacientes === '') {
        this.juevesTiempoAtencion = '';
        return;
      }
      if (parseInt(this.juevesPacientes, 10) === 0) {
        this.juevesPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.juevesPacientes)) {
        this.juevesPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarViernesPacientes() {
      if (this.viernesPacientes === '') {
        this.viernesTiempoAtencion = '';
        return;
      }
      if (parseInt(this.viernesPacientes, 10) === 0) {
        this.viernesPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.viernesPacientes)) {
        this.viernesPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarSabadoPacientes() {
      if (this.sabadoPacientes === '') {
        this.sabadoTiempoAtencion = '';
        return;
      }
      if (parseInt(this.sabadoPacientes, 10) === 0) {
        this.sabadoPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.sabadoPacientes)) {
        this.sabadoPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    reValidarDomingoPacientes() {
      if (this.domingoPacientes === '') {
        this.domingoTiempoAtencion = '';
        return;
      }
      if (parseInt(this.domingoPacientes, 10) === 0) {
        this.domingoPacientes = '1';
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.domingoPacientes)) {
        this.domingoPacientes = '';
      }
      this.ejecutarCalculosTiempoAtencion();
    },
    convertSegsToTime(segs) {
      let h = Math.floor(segs / 3600);
      let m = Math.floor((segs / 60) % 60);
      let s = Math.floor(segs % 60);
      h = h < 10 ? `0${h}` : h;
      m = m < 10 ? `0${m}` : m;
      s = s < 10 ? `0${s}` : s;
      return `${h}:${m}:${s}`;
    },
    ejecutarCalculosTiempoAtencion() {
      if (
        this.lunesIngreso !== ''
        && this.lunesSalida !== ''
        && this.lunesPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.lunesSalida >= this.lunesIngreso) {
          const inicio = this.lunesIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.lunesSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.lunesPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.lunesTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.lunesTiempoAtencion = '';
          }
        } else {
          this.lunesTiempoAtencion = '';
        }
      } else {
        this.lunesTiempoAtencion = '';
      }
      // martes
      if (
        this.martesIngreso !== ''
        && this.martesSalida !== ''
        && this.martesPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.martesSalida >= this.martesIngreso) {
          const inicio = this.martesIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.martesSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.martesPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.martesTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.martesTiempoAtencion = '';
          }
        } else {
          this.martesTiempoAtencion = '';
        }
      } else {
        this.martesTiempoAtencion = '';
      }
      // miercoles
      if (
        this.miercolesIngreso !== ''
        && this.miercolesSalida !== ''
        && this.miercolesPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.miercolesSalida >= this.miercolesIngreso) {
          const inicio = this.miercolesIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.miercolesSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.miercolesPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.miercolesTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.miercolesTiempoAtencion = '';
          }
        } else {
          this.miercolesTiempoAtencion = '';
        }
      } else {
        this.miercolesTiempoAtencion = '';
      }
      // jueves
      if (
        this.juevesIngreso !== ''
        && this.juevesSalida !== ''
        && this.juevesPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.juevesSalida >= this.juevesIngreso) {
          const inicio = this.juevesIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.juevesSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.juevesPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.juevesTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.juevesTiempoAtencion = '';
          }
        } else {
          this.juevesTiempoAtencion = '';
        }
      } else {
        this.juevesTiempoAtencion = '';
      }
      // viernes
      if (
        this.viernesIngreso !== ''
        && this.viernesSalida !== ''
        && this.viernesPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.viernesSalida >= this.viernesIngreso) {
          const inicio = this.viernesIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.viernesSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.viernesPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.viernesTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.viernesTiempoAtencion = '';
          }
        } else {
          this.viernesTiempoAtencion = '';
        }
      } else {
        this.viernesTiempoAtencion = '';
      }
      // sabado
      if (
        this.sabadoIngreso !== ''
        && this.sabadoSalida !== ''
        && this.sabadoPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.sabadoSalida >= this.sabadoIngreso) {
          const inicio = this.sabadoIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.sabadoSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.sabadoPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.sabadoTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.sabadoTiempoAtencion = '';
          }
        } else {
          this.sabadoTiempoAtencion = '';
        }
      } else {
        this.sabadoTiempoAtencion = '';
      }
      // domingo
      if (
        this.domingoIngreso !== ''
        && this.domingoSalida !== ''
        && this.domingoPacientes !== ''
        && this.tiempoDescanso !== ''
      ) {
        if (this.domingoSalida >= this.domingoIngreso) {
          const inicio = this.domingoIngreso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const final = this.domingoSalida.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const h = final - inicio;
          const cantidad = parseInt(this.domingoPacientes, 10);
          const td = this.tiempoDescanso.split(':')
            .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
          const segs = ((h * 60) / cantidad) - (td * 60);
          if (segs >= 0) {
            this.domingoTiempoAtencion = this.convertSegsToTime(segs);
          } else {
            this.domingoTiempoAtencion = '';
          }
        } else {
          this.domingoTiempoAtencion = '';
        }
      } else {
        this.domingoTiempoAtencion = '';
      }
    },
    validarModal(bvModalEvt) {
      if (this.lunesIngreso !== '' && this.lunesSalida !== '') {
        if (this.lunesPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el lunes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.lunesConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el lunes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.martesIngreso !== '' && this.martesSalida !== '') {
        if (this.martesPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el martes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.martesConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el martes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.miercolesIngreso !== '' && this.miercolesSalida !== '') {
        if (this.miercolesPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el miercoles.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.miercolesConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el miercoles.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.juevesIngreso !== '' && this.juevesSalida !== '') {
        if (this.juevesPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el jueves.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.juevesConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el jueves.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.viernesIngreso !== '' && this.viernesSalida !== '') {
        if (this.viernesPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el viernes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.viernesConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el viernes.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.sabadoIngreso !== '' && this.sabadoSalida !== '') {
        if (this.sabadoPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el sabado.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
        if (this.sabadoConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el sabado.', 'warn');
          bvModalEvt.preventDefault();
          return;
        }
      }
      if (this.domingoIngreso !== '' && this.domingoSalida !== '') {
        if (this.domingoPacientes === '') {
          util.showNotify('Complete la cantidad de pacientes para el domingo.', 'warn');
          bvModalEvt.preventDefault();
        }
        if (this.domingoConsultorioSelected === null) {
          util.showNotify('Seleccione un consultorio para el domingo.', 'warn');
          bvModalEvt.preventDefault();
        }
      }
    },
  },
  computed: {
    ...mapFields('modalTurno2', [
      'turnoId',
      'listaConsultorio',
      'tiempoDescanso',
      'checkLunes',
      'checkMartes',
      'checkMiercoles',
      'checkJueves',
      'checkViernes',
      'checkSabado',
      'checkDomingo',
      'lunesIngreso',
      'martesIngreso',
      'miercolesIngreso',
      'juevesIngreso',
      'viernesIngreso',
      'sabadoIngreso',
      'domingoIngreso',
      'lunesSalida',
      'martesSalida',
      'miercolesSalida',
      'juevesSalida',
      'viernesSalida',
      'sabadoSalida',
      'domingoSalida',
      'lunesPacientes',
      'martesPacientes',
      'miercolesPacientes',
      'juevesPacientes',
      'viernesPacientes',
      'sabadoPacientes',
      'domingoPacientes',
      'lunesTiempoAtencion',
      'martesTiempoAtencion',
      'miercolesTiempoAtencion',
      'juevesTiempoAtencion',
      'viernesTiempoAtencion',
      'sabadoTiempoAtencion',
      'domingoTiempoAtencion',
      'lunesConsultorioSelected',
      'martesConsultorioSelected',
      'miercolesConsultorioSelected',
      'juevesConsultorioSelected',
      'viernesConsultorioSelected',
      'sabadoConsultorioSelected',
      'domingoConsultorioSelected',
    ]),
  },
};
</script>

<style scoped>
.accion-rapida {
  cursor: pointer;
}
.accion-rapida:active {
  color: orange !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
